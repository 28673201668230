html,
body,
#root {
	height: 100%; /* Use 100% instead of 100vh for better compatibility */
	margin: 0;
	padding: 0;
	overflow-x: hidden; /* Prevent horizontal scroll */
	width: 100%;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.app-container {
	min-height: 100%; /* Ensure it takes at least the full viewport height */
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto; /* Allow vertical scrolling only if needed */
}

.router-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%; /* Prevent extra width */
}

.error-container,
.loading-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

/* General scrollbar improvements for a smoother experience */
html {
	scroll-behavior: smooth;
}

.Toastify__toast {
	font-family: 'Orbitron', sans-serif; /* Replace 'YourCustomFont' with your preferred font */
	color: #ffffff; /* Set text color */
	background-color: #000; /* Set background color */
}

.Toastify__toast--success {
	background-color: #3ccc90; /* Success background */
	color: #000; /* Success text color */
	font-weight: 600;
}

.Toastify__toast--error {
	background-color: #ea514c; /* Error background */
	color: #000; /* Error text color */
	font-weight: 600;
}

.Toastify__toast--warning {
	background-color: #f0d352; /* Warning background */
	color: #000; /* Warning text color */
	font-weight: 600;
}

.Toastify__toast--info {
	background-color: #11deea; /* Info background */
	color: #000; /* Info text color */
	font-weight: 600;
}

.spotlight_swivel {
	position: absolute;
	z-index: 2;
}

.lamp {
	position: absolute;
	width: 10vh;
	height: 400vh;
	background-image: radial-gradient(
		ellipse,
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0.1) 50%,
		rgba(255, 255, 255, 0.1) 100%
	);
	top: -220vh;
	left: 0;
	transform: perspective(23vh) rotateX(30deg);
}

#lamp-1 {
	left: 0px;
	transform: rotate(45deg);
	&.start {
		animation: rotate_spotlight 5s linear infinite;
	}
}

#lamp-2 {
	right: 10vh;
	transform: rotate(-45deg);
	&.start {
		animation: rotate_spotlight2 5s linear infinite;
	}
}

@keyframes rotate_spotlight {
	0% {
		transform: rotate(45deg) scaleY(1) translateY(0);
	}
	15% {
		transform: rotate(0deg) scaleY(1) translateY(0);
	}
	25% {
		transform: rotate(-45deg) scaleY(1.1) translateY(-3vh);
	}
	50% {
		transform: rotate(0deg) scaleY(1) translateY(0);
	}
	75% {
		transform: rotate(45deg) scaleY(1.1) translateY(-3vh);
	}
}

@keyframes rotate_spotlight2 {
	0% {
		transform: rotate(-45deg) scaleY(1) translateY(0);
	}
	15% {
		transform: rotate(0deg) scaleY(1) translateY(0);
	}
	25% {
		transform: rotate(45deg) scaleY(1.1) translateY(-3vh);
	}
	50% {
		transform: rotate(0deg) scaleY(1) translateY(0);
	}
	75% {
		transform: rotate(-45deg) scaleY(1.1) translateY(-3vh);
	}
}

.light-ray-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;

}

.content {
	position: relative;
	z-index: 1;
}

.ray {
	position: absolute;
	top: -10%;
	left: 65%;
	width: 35%; /* Main ray width */
	height: 120%;
	background-color: rgba(255, 255, 255, 0.2);
	transform: rotate(10deg);
	animation: move-ray 3s cubic-bezier(0.8, 0.8, 0.67, 0.8) infinite;
	mix-blend-mode: 'screen';
}

.ray-13 {
	position: absolute;
	top: -10%;
	left: 65%;
	width: 35%; /* Main ray width */
	height: 120%;
	background-color: rgba(255, 255, 255, 0.3);
	transform: rotate(10deg);
	animation: move-ray 3s cubic-bezier(0.8, 0.8, 0.67, 0.8) infinite;
	mix-blend-mode: 'screen';
}

.ray-15 {
	position: absolute;
	top: -10%;
	left: 65%;
	width: 35%; /* Main ray width */
	height: 120%;
	background-color: rgba(255, 255, 255, 0.4);
	transform: rotate(10deg);
	animation: move-ray 3s cubic-bezier(0.8, 0.8, 0.67, 0.8) infinite;
	mix-blend-mode: 'screen';
}

.ray-thin {
	position: absolute;
	top: -10%;
	left: 100%;
	width: 10%; /* Thinner width for secondary ray */
	height: 120%;
	background-color: rgba(255, 255, 255, 0.1); /* Slightly lighter color */
	transform: rotate(10deg); /* Same angle as the main ray */
	animation: move-ray 3s cubic-bezier(0.9, 0.55, 0.67, 0.9) infinite;
	animation-delay: 0.5s; /* Small delay to follow the main ray */
}


.ray-ll {
  position: absolute;
  top: -5px;
  left: 65%;
  width: 35%; /* Main ray width */
  height: 120%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 90%);
  transform: rotate(30deg);
  animation: slideBlink 2s ease-in-out infinite;
}

.ray-left {
    content: "";
    display: block;
    width: 30%;
    height: 120%;
    background: linear-gradient(90deg, rgba(255,255,255,0.2) 10%, rgba(255,255,255,0.2) 10%, rgba(255,255,255,0.2) 20%);
    z-index: 1;
    transform: rotate(10deg);
    position: absolute;
    top: -10%;
    animation: slideBlink 2s ease-in-out infinite
}

@keyframes move-ray {
  0% {
    left: 120%;
  }
  100% {
    left: -50%;
  }
}



@keyframes slideBlink {
    0% {
        left: -50%
    }

    20% {
        left: 120%
    }

    100% {
        left: 120%
    }
}

.mix-blend-color {
    mix-blend-mode: color;
}

.terracotta-image {  
  /* Additional effects to mimic terra cotta texture */
  filter: drop-shadow(1px 2px 4px rgba(0,0,0,1)); 
}

.terracotta-image-l7 {  
  filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 0.2));
}

.terracotta-image-l9 {  
  filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 0.35));
}

.terracotta-image-l11 {  
  filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 0.5));
}

.terracotta-image-l13 {  
  filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 0.75));
}

.terracotta-image-l15 {  
  filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 1));
}



@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.8);
  }
}

.shiny-item {
  animation: glow 2s infinite;
}

{/*     content: "";
    display: block;
    width: 20%;
    height: 150%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 90%);
    z-index: 1;
    transform: rotate(30deg);
    position: absolute;
    top: -5px;
    animation: slideBlink 2s ease-in-out infinite;*/}



body {
	font-family: 'Titillium Web', sans-serif;
	background: #191b28;
}

.spinwheel-component {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.roulette-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.roulette-wrapper .selector {
	width: 1px;
	left: 50%;
	height: 100%;
	transform: translate(-50%, 0%);
	position: absolute;
	z-index: 2;
}

.roulette-wrapper .wheel {
	display: flex;
}

.roulette-wrapper .wheel .row {
	display: flex;
}

.roulette-wrapper .wheel .row .card {
	height: 150px;
	width: 100px;
	margin: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1.5em;
}

.card.red {
	background: #f95146;
}

.card.black {
	background: #2d3035;
}

.card.green {
	background: #00c74d;
}

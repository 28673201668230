@keyframes slide-up {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

@keyframes bg-fade {
	0% {
		opacity: 0.5; /* Starting background opacity */
	}
	100% {
		opacity: 0; /* Fully transparent */
	}
}

.animate-slide-up {
	animation: slide-up 1.5s ease-out forwards;
}

.animate-bg-fade {
	animation: bg-fade 3s ease-out forwards;
}

@keyframes pulsate-bg {
	0% {
		background-color: rgba(239, 68, 68, 0.1); /* Assuming red-550 is similar to #EF4444 */
	}
	50% {
		background-color: rgba(239, 68, 68, 0.5); /* Midpoint opacity */
	}
	100% {
		background-color: rgba(239, 68, 68, 0.1); /* Back to original opacity */
	}
}

.animate-pulsate-bg {
	animation: pulsate-bg 2s ease-in-out infinite;
}

@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-ExtraBold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Orbitron';
	src: url('../assets/fonts/Orbitron/static/Orbitron-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@import url('@fontsource/oxanium/400.css');
@import url('@fontsource/oxanium/500.css');
@import url('@fontsource/oxanium/600.css');
@import url('@fontsource/oxanium/700.css');

/* Inventory.css */

.inventory-container {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.inventory-content {
	display: flex;
}

.inventory-list {
	flex: 1;
	margin-right: 20px;
}

.inventory-list ul {
	list-style-type: none;
	padding: 0;
}

.inventory-list li {
	padding: 10px;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
}

.inventory-list li:hover {
	background-color: #f0f0f0;
}

.inventory-details {
	flex: 2;
	border-left: 1px solid #ccc;
	padding-left: 20px;
}

.inventory-details h3 {
	margin-top: 0;
}

.inventory-details ul {
	list-style-type: none;
	padding: 0;
}

.inventory-details button {
	margin-right: 10px;
}

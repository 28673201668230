@keyframes pulsate-bg-red {
	0% {
		opacity: 0.1;
		background-color: #ff6058;
	}
	50% {
		opacity: 0.2;
		background-color: #ff6058;
	}
	100% {
		opacity: 0.1;
		background-color: #ff6058;
	}
}

@keyframes pulsate-bg-blue {
	0% {
		opacity: 0.1;
		background-color: #11deea;
	}
	50% {
		opacity: 0.2;
		background-color: #11deea;
	}
	100% {
		opacity: 0.1;
		background-color: #11deea;
	}
}

.animate-pulsate-bg-red {
	animation: pulsate-bg-red 3s ease-in-out infinite;
}

.animate-pulsate-bg-blue {
	animation: pulsate-bg-blue 3s ease-in-out infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 0;
	}
	50% {
		transform: scale(1.01);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

.pulse-animation {
	animation: pulse 0.5s ease forwards;
}

@keyframes border-opacity-pulse {
	0% {
		border-opacity: 0; /* Start with transparent border */
	}
	50% {
		border-opacity: 0.1; /* Half opacity at midpoint */
	}
	100% {
		border-opacity: 0; /* Back to transparent */
	}
}

.border-pulse-animation {
	animation: border-opacity-pulse 0.5s ease forwards;
	animation-iteration-count: 1;
}
